import React from 'react'

import theme from '../theme/theme'

class Forgot extends React.Component {
	componentDidMount() {
		const queryURL = new URLSearchParams(window.location.search)
		typeof window !== 'undefined' && window.location.replace(theme.redirects.forgot + '/?email=' + queryURL.get('email'))
	}

	render () {
		return true
	}
}

export default Forgot
